import { Beaker, Bot, Zap, Mail } from 'lucide-react';
import { Helmet } from 'react-helmet';

const LandingPage = () => (
    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "name": "Kaio Labs",
                    "description": "Pioneering sustainable fuels and chemicals through innovative catalysis technology",
                    "url": "https://kaiolabs.com",
                    "logo": "https://kaiolabs.com/logo.png",
                    "contactPoint": {
                        "@type": "ContactPoint",
                        "email": "will@kaiolabs.com",
                        "contactType": "customer service"
                    },
                    "sameAs": [
                        "https://www.linkedin.com/company/kaio-labs"
                    ]
                })}
            </script>
        </Helmet>
        <div className="h-screen w-full bg-slate-900 text-white p-12 flex flex-col justify-center items-center">
            <div className="flex items-center space-x-6 mb-8">
                <Beaker size={48} className="text-teal-500 hover:scale-110 transition-transform duration-200" />
                <Bot size={48} className="text-green-500 hover:scale-110 transition-transform duration-200" />
                <Zap size={48} className="text-purple-500 hover:scale-110 transition-transform duration-200" />
            </div>

            <h1 className="text-6xl font-bold mb-6 tracking-tight">Kaio Labs</h1>
            <h2 className="text-2xl text-teal-500 mb-12 tracking-wide text-center md:text-left md:whitespace-nowrap">
                Unlocking sustainable fuels and chemicals
            </h2>

            <a
                href="mailto:will@kaiolabs.com"
                className="flex items-center space-x-3 text-gray-300 hover:text-purple-500 transition-colors duration-300"
            >
                <Mail className="w-6 h-6" />
                <span className="text-lg">Contact</span>
            </a>
        </div>
    </>
);

export default LandingPage;