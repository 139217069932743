import React, { useState, useEffect } from 'react';

const useResponsiveStyles = () => {
    const [styles, setStyles] = useState({
        container: {
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '20px',
            boxSizing: 'border-box',
            paddingBottom: 'max(env(safe-area-inset-bottom), 20px)',
            zIndex: 1000,
        },
        button: {
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            margin: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 'calc(1vh + 5px) 0',
            width: '100%',
        },
        text: {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: 'calc(0.8rem + 1vw)',
            fontWeight: 300,
            textShadow: '0 0 5px rgba(255,255,255,0.5)',
            color: 'white',
            margin: '0 0 10px 0',
            textAlign: 'center',
        },
        line: {
            width: '60px',
            height: '4px',
            backgroundColor: 'white',
            margin: '10px 0 15px 0', // Reduced bottom margin
        },
        contactButtonWrapper: {
            marginTop: '10px', // Reduced top margin
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
        },
    });

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) { // Mobile devices
                setStyles(prevStyles => ({
                    ...prevStyles,
                    container: {
                        ...prevStyles.container,
                        paddingBottom: 'max(env(safe-area-inset-bottom), 30px)', // Reduced padding
                    },
                    button: {
                        ...prevStyles.button,
                        padding: 'calc(1vh + 5px) 0', // Reduced padding
                    },
                    text: {
                        ...prevStyles.text,
                        fontSize: 'calc(0.9rem + 1vw)',
                    },
                    line: {
                        ...prevStyles.line,
                        margin: '5px 0 10px 0', // Further reduced margins for mobile
                    },
                    contactButtonWrapper: {
                        ...prevStyles.contactButtonWrapper,
                        marginTop: '5px', // Reduced top margin for mobile
                    },
                }));
            } else { // Laptops and larger screens
                setStyles(prevStyles => ({
                    ...prevStyles,
                    container: {
                        ...prevStyles.container,
                        paddingBottom: 'max(env(safe-area-inset-bottom), 20px)',
                    },
                    button: {
                        ...prevStyles.button,
                        padding: 'calc(1vh + 10px) 0',
                    },
                    text: {
                        ...prevStyles.text,
                        fontSize: 'calc(0.8rem + 1vw)',
                    },
                    line: {
                        ...prevStyles.line,
                        margin: '10px 0 15px 0',
                    },
                    contactButtonWrapper: {
                        ...prevStyles.contactButtonWrapper,
                        marginTop: '10px',
                    },
                }));
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call once to set initial state

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return styles;
};

const BottomComponent = ({ isCO2, handleMoleculeChange, contactButton }) => {
    const styles = useResponsiveStyles();

    return (
        <div style={styles.container}>
            <button onClick={handleMoleculeChange} style={styles.button}>
                <p style={styles.text}>Catalysing A Sustainable Future</p>
                <div style={styles.line}></div>
            </button>
            <div style={styles.contactButtonWrapper}>
                {contactButton}
            </div>
        </div>
    );
};

export default BottomComponent;