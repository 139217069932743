// src/utils/favicon.js
const zapIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M13 2 3 14h9l-1 8 10-12h-9l1-8z"/></svg>`;

const svgToDataURL = (svgString) => {
    const svg = svgString.replace('currentColor', '#a855f7'); // text-purple-500
    return `data:image/svg+xml;base64,${btoa(svg)}`;
};

export const setZapFavicon = () => {
    if (typeof window !== 'undefined') {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = svgToDataURL(zapIcon);
        document.head.appendChild(link);
    }
};