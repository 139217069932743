import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'; 

const rootElement = document.getElementById('root');
if (rootElement) {
    rootElement.innerHTML = ''; // Clear the no-js content
    ReactDOM.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        rootElement
    );
}