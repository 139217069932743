import React, { useEffect } from 'react';
import MoleculeLandingPage from './CO2LandingPage';
import TestContactOverlay from './TestContactOverlay';
import SimpleContactButton from './SimpleContactButton';
import './index.css';
import LandingPage from './SimpleLandingPage';
import { setZapFavicon } from './utils/favicon';

function App() {
    useEffect(() => {
        setZapFavicon();
    }, []);

    return (
        <div className="App">
            {/* <MoleculeLandingPage /> */}
            {/* <SimpleContactButton /> */}
            <LandingPage />
        </div>
    );
}

export default App;